import React from 'react'
import Title from './Title'

export default function Checkout() {
    let cartItem = //json
    {
        "cartItems": [
            { "id": 2, "name": "kagami", "img": "kagami.jpeg", "description": "kagami", 'quantity': 1 },
            { "id": 3, "name": "kaida", "img": "kaida.jpeg", "description": "kaida", 'quantity': 3 }

        ]
    }
    //let cartItems be array
    let { cartItems } = cartItem
    //if length <= 0 return true ,else false
    let cartEmpty = cartItems.length <= 0 ? true : false

    return (
        <div>
            <Title mainTitle='Checkout' />
            {
                cartEmpty &&
                <div className='cart'>
                    no items
                </div>
            }
            {
                !cartEmpty &&
                cartItems.map((product) => (
                    <div className='cart' key={product.id}>
                        {product.name} <br />
                        <img style={{ width: '30%' }}
                            src={process.env.PUBLIC_URL + '/img/' + product.img} /><br />
                            {product.description}<br />
                            {product.quantity}
                            </div>
                ))

            }
        </div>
    )
}
