import React from 'react'

export default function Title({mainTitle,subtitle}) {
    return (
        <div>
            <h1 style={{ backgroundColor: 'cyan', borderBottom: '5px solid red' }}>
                {mainTitle}
                {subtitle}
            </h1>
        </div>
    )
}
/*==>
export default function Title(props) {
    return (
        <div>
            <h1 style={{ backgroundColor: 'cyan', borderBottom: '5px solid red' }}>
                {props.mainTitle}
                {props.subtitle}
            </h1>
        </div>
    )
}
*/ 