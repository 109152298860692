import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Title from './Title'

export default function () {
    let params = useParams()

    return (
        <div>
            <Title mainTitle={params.id+" detail"}/>

            <Link to={'/'}>index</Link>
        </div>
    )
}
