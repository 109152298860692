//use import to import img
//import kenmo from './kagami.jpeg'
import css from './ProductList.module.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Title from './Title'

export default function ProductList() {
    let productList = [
        { "id": 1, "name": "kenmochi", "img": "kenmo.jpeg", "description": "kenmochi" },
        { "id": 2, "name": "kagami", "img": "kagami.jpeg", "description": "kagami" },
        { "id": 3, "name": "kaida", "img": "kaida.jpeg", "description": "kaida" }
    ]

    //useState will return an array
    //[object, function] //function-->set_object name
    //use usestate-->value be active
    const [product, setProduct] = useState('apple')
    const handleClick = () => {
        setProduct('react')
        //console log will run before component actions
        console.log(product)
    }

    const [showproduct, setShowproduct] = useState(false)

    //用map裡面的array功能
    return (
        <div>
            <Title mainTitle="main title"
                subtitle="good"/>
            <h1 className={css.productBorder}>module css</h1>
            <button onClick={handleClick}>{product}</button><br />

            {/*if showproduct=true -->not show the button
            */}
            {showproduct && <button onClick={() => setShowproduct(false)}>Disappear</button>}
            {/*if showproduct=false --> show the button
            */}
            {!showproduct && <button onClick={() => setShowproduct(true)}>Appear</button>}


            {/*use import to import img
               <img src={kenmo} />
            */}

            {/*如果要用PUBLIC的東西要加process.env.PUBLIC_URL
                <img src={process.env.PUBLIC_URL + "/img/kagami.jpeg"} />
            */}
            <div>
                {
                    showproduct && productList.map(product => (
                        <div className='productBorder' key={product.id}>
                            {product.name} <br />
                            <Link to={'/product_detail/' + product.id}>
                                <img style={{ width: '30%' }}
                                    src={process.env.PUBLIC_URL + '/img/' + product.img} /><br />
                                {product.description} <br />
                            </Link>
                        </div>
                    ))

                    /* equals-->
                    -bcz only one object, only one line to return
                    productList.map((product) => {
                        return (
                            <p>{product.name}</p>
                        )
                    })
                    */
                }
            </div>
        </div>
    )
}
