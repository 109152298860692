import ProductList from './ProductList';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Checkout from './Checkout';
import ProductDetail from './ProductDetail';

function App() {
  return (
    <BrowserRouter>
      <Link to="/">index</Link>
      <Link to="/checkout">checkout</Link>
      <Link to="/product_detail">ProductDetail</Link>

      <Routes>
        <Route path='/' element={<ProductList />} />
        <Route path='/checkout' element={<Checkout />} />
        
        <Route path='/product_detail' element={<ProductDetail />} >
          <Route path=':id'  element={<ProductDetail />} />
        </Route>

        <Route path='*' element={<p >404</p>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
